import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";

// 查询计量表列表
export function meterList(params) {
    return http.post(`${resquest}/cusGaugesBase/list`, params);
}

// 删除计量表
export function delMeter(params) {
    return http.post(`${resquest}/cusGaugesBase/remove`, params);
}

// 新增计量表
export function addMeter(params) {
    return http.post(`${resquest}/cusGaugesBase/add`, params);
}

// 修改计量表
export function editMeter(params) {
    return http.post(`${resquest}/cusGaugesBase/edit`, params);
}

// 查询传感器列表
export function sensorList(params) {
    return http.post(`${resquest}/cusGaugesSensor/list`, params);
}

// 删除传感器
export function delSensor(params) {
    return http.post(`${resquest}/cusGaugesSensor/remove`, params);
}

// 新增传感器
export function addSensor(params) {
    return http.post(`${resquest}/cusGaugesSensor/add`, params);
}

// 修改传感器
export function editSensor(params) {
    return http.post(`${resquest}/cusGaugesSensor/edit`, params);
}

// 查询虚拟表列表
export function virtualMeterList(params) {
    return http.post(`${resquest}/cusGaugesVirtually/list`, params);
}

// 删除虚拟表
export function delVirtualMeter(params) {
    return http.post(`${resquest}/cusGaugesVirtually/remove`, params);
}

// 新增虚拟表
export function addVirtualMeter(params) {
    return http.post(`${resquest}/cusGaugesVirtually/add`, params);
}

// 修改虚拟表
export function editVirtualMeter(params) {
    return http.post(`${resquest}/cusGaugesVirtually/edit`, params);
}

// 查询离线表列表
export function outlineMeterList(params) {
    return http.post(`${resquest}/cusGaugesOnline/list`, params);
}

// 删除离线表
export function delOutlineMeter(params) {
    return http.post(`${resquest}/cusGaugesOnline/remove`, params);
}

// 新增离线表
export function addOutlineMeter(params) {
    return http.post(`${resquest}/cusGaugesOnline/add`, params);
}

// 修改离线表
export function editOutlineMeter(params) {
    return http.post(`${resquest}/cusGaugesOnline/edit`, params);
}

// 查询离线表文件列表
export function outlineMeterFileList(params) {
    return http.post(`${resquest}/cusGaugesOnlineFile/list`, params);
}

// 删除离线表文件
export function delOutlineMeterFile(params) {
    return http.post(`${resquest}/cusGaugesOnlineFile/remove`, params);
}

//上传离线表文件
export function uploadOutlineMeterFile(params) {
    return http.post(`${resquest}/cusGaugesOnlineFile/remove`, params);
}

//获取计量表已绑定的数据点
export function getBindPointList(params) {
    return http.post(`${resquest}/cusGaugesPoint/002-getAlreadyBindPoint`, params);
}

//获取计量表未绑定的数据点
export function getNotBindPointList(params) {
    return http.post(`${resquest}/cusGaugesPoint/003-getWithoutBindPoint`, params);
}

//计量表绑定数据点
export function addBindPoint(params) {
    return http.post(`${resquest}/cusGaugesPoint/001-PointBindGauges`, params);
}

//计量表解绑数据点
export function delBindPoint(params) {
    return http.post(`${resquest}/cusGaugesPoint/004-remove`, params);
}