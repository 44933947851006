import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";
// 查询数据点列表
export function pointList(params) {
  return http.post(`${resquest}/cusPoint/list`, params);
}

// 删除数据点
export function delPoint(params) {
    return http.post(`${resquest}/cusPoint/remove`, params);
}

// 新增数据点
export function addPoint(params) {
    return http.post(`${resquest}/cusPoint/add`, params);
  }
  
  // 修改数据点
  export function editPoint(params) {
      return http.post(`${resquest}/cusPoint/edit`, params);
  }
