import { equipList,energyList } from "@/api/equip";
import { meterList,virtualMeterList,outlineMeterList,sensorList } from "@/api/meter";
import { pointList } from "@/api/point";
//获取设备列表
function getEquipList() {
    let equiments=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999,
        projectId: Number(sessionStorage.getItem("projectId")),
        projectName:sessionStorage.getItem("projectName"),
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,  
    };
    equipList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                equiments.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        }
    });
    return equiments
}

//获取计量表列表
function getMeterList(equimentId) {
    let meters=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,  
        deviceId:equimentId
    };
    meterList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                meters.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        }
    });
    return meters
}
//获取传感器列表
function getSensorList(equimentId) {
    let meters=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,  
        deviceId:equimentId
    };
    sensorList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                meters.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        }
    });
    return meters
}
//获取虚拟表列表
function getVirtualMeterList(equimentId) {
    let meters=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,  
        deviceId:equimentId
    };
    virtualMeterList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                meters.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        }
    });
    return meters
}
//获取离线表列表
function getOutlineMeterList(equimentId) {
    let meters=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,  
        deviceId:equimentId
    };
    outlineMeterList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                meters.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        }
    });
    return meters
}

//获取能源类型列表
function getEnergyList() {
    let energys=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999
    };
    energyList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                energys.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        }
    });
    return energys
}

//获取数据点列表
function getPointList(equipmentId,meterId){
    let points=[]
    let param = {
        pageNum: 1,
        pageSize: 999999999,
        gaugesId: meterId,
        deviceId: equipmentId,
    };
    if (equipmentId == "-1") {
        delete param.deviceId;
    }
    if (meterId == "-1") {
        delete param.gaugesId;
    }
    pointList(param).then((res) => {
        if (res.data.code == 0) {
            for(let i=0;i<res.data.rows.length;i++){
                let t={};
                t.name=res.data.rows[i].name;
                t.id=res.data.rows[i].id;
                points.push(t)
            }
        } else {
            this.$message.warning(res.data.msg);
        } 
    });
    return points
}

export {getEnergyList,getMeterList,getEquipList,getVirtualMeterList,getOutlineMeterList,getSensorList,getPointList}
