import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";
// 查询设备列表
export function equipList(params) {
  return http.post(`${resquest}/CusDevice/list`, params);
}

// 删除设备
export function delEquip(params) {
    return http.post(`${resquest}/CusDevice/remove`, params);
}

// 新增设备
export function addEquip(params) {
    return http.post(`${resquest}/CusDevice/add`, params);
}

// 修改设备
export function editEquip(params) {
    return http.post(`${resquest}/CusDevice/edit`, params);
}

//查询能源类型
export function energyList(params) {
    return http.post(`${resquest}/cusPowerCategory/list`, params);
  }

//上传设备图片
export function uploadPic(params){ 
    return http.post(`${resquest}/002-uploadFileForJson`, params,);
}

//获取设备已绑定的计量表
export function getBindMeterList(params) {
    return http.post(`${resquest}/cusDeviceGauges/002-getAlreadyBindGauges`, params);
}

//获取设备未绑定的计量表
export function getNotBindMeterList(params) {
    return http.post(`${resquest}/cusDeviceGauges/003-getWithoutBindGauges`, params);
}

//设备绑定计量表
export function addBindMeter(params) {
    return http.post(`${resquest}/cusDeviceGauges/001-DeviceBindGauges`, params);
}

//设备解绑计量表
export function delBindMeter(params) {
    return http.post(`${resquest}/cusDeviceGauges/004-remove`, params);
}
//获取实时信息
export function getRealInfo(params) {
    return http.post(`${resquest}/CusDevice/001-realTimeByDeviceId`, params);
}